import React, {useEffect, useState} from 'react';
import NavHeader from '../../components/NavHeader/NavHeader'
import UserSettings from "../../components/UserSettings/UserSettings";
import axiosConfig from "../../utils/api";

function Settings() {
    const [userData, setUserData] = useState([]);
    const getUserData = () => {
        axiosConfig.get('get_user_data').then(res => {
            setUserData(res.data.user)
        }).catch(err => {
            console.log(err)
        })
    };
    useEffect(() => {
        getUserData()
    }, []);
    return (
        <>
            <NavHeader/>
            <div className='container' style={{marginTop: '30px'}}>
                <div className='row'>
                    <div className='col-md-12'>
                        <h3>Настройки</h3>
                        <UserSettings userData={userData} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;
