import React, {useState, useEffect} from 'react';
import axiosConfig from '../../utils/api'
import { useHistory } from "react-router-dom";
import DataPreloader from '../../components/DataPreloader/DataPreloader'

function AuthForm({authType}) {
    const history = useHistory();
    const [formData, setFormData] = useState({email: null, password: null});
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [badCreds, setBadCreds] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setFormData({email: email, password: password});

    };

    useEffect(() => {
        setBadCreds(null)
        if (email !== null && password !== null) {
            setDataLoading(true)
            let payload = {
                email: email,
                password: password
            };
            if(authType === 'signin') {
                console.log(formData, 'auth')
                axiosConfig.post('login', payload)
                    .then(res => {
                        if (res.data && res.data.access_token) {
                            localStorage.setItem('access_token', res.data.access_token)
                            setTimeout(() => {
                                setDataLoading(false);
                                history.push("/dashboard");
                            }, 100);
                        }
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            setDataLoading(false);
                            setBadCreds('неверный логин или пароль')
                        }
                    })
            }
        }
    }, [formData]);

    return (
        <>
            <div className='card'>
                <div className='card-body'>

                    <div className="form rui-sign-form rui-sign-form-cloud">
                        { dataLoading && <div className="preloader__wrapper"><DataPreloader /></div> }
                        <form onSubmit={handleFormSubmit}>
                        <div className="row vertical-gap sm-gap justify-content-center">
                            <div className="col-12">
                                {authType === 'signup'
                                    ? <h1 className="display-4 mb-10 text-center">Регистрация</h1>
                                    : <h1 className="display-4 mb-10 text-center">Войти в личный кабинет</h1>
                                }
                            </div>
                            <div className="col-12">
                                <input type="email" className="form-control" id="email"
                                       onChange={e => setEmail(e.target.value)} required placeholder="Email"/>
                            </div>
                            <div className="col-12">
                                <input type="password" className="form-control" id="password"
                                       onChange={e => setPassword(e.target.value)} required placeholder="Пароль"/>
                            </div>
                            <div className="col-12">
                                <p className="mb-0 text-danger">{badCreds}</p>
                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-end">
                                    {authType === 'signup'
                                        ? null
                                        : <a href="#" className="fs-13">Забыли пароль?</a>
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                {authType === 'signup'
                                    ? <button type="submit" className="btn btn-success btn-lg btn-block text-center">
                                        Создать аккаунт
                                      </button>
                                    : <button type="submit"  className="btn btn-success btn-lg btn-block text-center">
                                         Войти
                                      </button>
                                }
                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-end">
                                    {authType === 'signup'
                                        ? <span>Уже есть аккаунт? <a href="/signin" className="fs-13">Войти</a></span>
                                        : <span>Нет аккаунта? <a href="/signup" className="fs-13">Зарегистрироваться</a></span>
                                    }
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}

export default AuthForm;
