import React, {useState, useEffect} from 'react';
import './LinksList.scss';
import ListItem from "../ListItem/ListItem";
import MobileListItem from "../ListItem/MobileListItem";
import axiosConfig from "../../utils/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function LinksList({linksList, getLinksList}) {
    const [showToast, setShowToast] = useState(false);
    const [copiedLink, setCopiedLink] = useState(false);

    const [linkToDelete, setLinkToDelete] = useState(null);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);

    const copyHandler = (e) => {
        let textField = document.createElement('textarea');
        textField.innerText = e;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
         toast.success('Ссылка скопирована в буфер обмена', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    };

    useEffect(() => {
        setTimeout(() => {
            setShowToast(false);
        }, 5000)
    }, [showToast]);

    const copyLinkHandler = (e) => {
        setCopiedLink(true);
        setShowToast(true);
        copyHandler(e);
    };

    const deleteLink = () => {
        axiosConfig.delete(`delete_link_by_id?link_id=${linkToDelete.uid}`)
            .then(res => {
                setShowDeletePopUp(false);
                toast.success('Ссылка успешно удалена', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setTimeout(() => {
                    getLinksList()
                }, 100)
        })
    };
    const deleteLinkHandler = (e) => {
        setShowDeletePopUp(true);
        setLinkToDelete(e)
    };


    return (
        <>
            <ToastContainer />

              <Popup open={showDeletePopUp} modal>
                <div className="card">
                    <div className='card-body'>
                        <h6 className="card-subtitle h3 mt-10 text-muted">Подтвердить удаление ссылки</h6>
                        <p className="card-text mb-5">Внимение, отменить удаление ссылки невозможно!</p>
                        <pre>{JSON.stringify(linkToDelete)}</pre>
                    </div>
                    <div className="card-footer text-muted text-right">
                        <button className="btn btn-danger" onClick={deleteLink}>Удалить</button>
                        <button className="btn btn-default" onClick={() => setShowDeletePopUp(false)}>Отменить</button>
                        </div>
                </div>
              </Popup>

            <div className="card show__desktop">
                <div className='card-body'>
                    <div className="list-group links-list__wrapper">
                        <div className='list-group-item list-group-item-action link-wrapper__header'>
                            <div className='creation-date header-element'>Дата создания</div>
                            <div className='initial-url header-element'>Финальная ссылка</div>
                            <div className='url-description header-element'>Описание</div>
                            <div className='shorted-url header-element'>Короткая ссылка</div>
                            <div className='link-clicks header-element'>Кликов</div>
                            <div className='link-tools header-element'>Управление</div>
                        </div>
                        {linksList.map((link, index) => {
                            return <ListItem key={index} active={link.active} linkData={link}
                                             onCopyLink={copyLinkHandler} onDeleteLink={() => deleteLinkHandler(link)}
                            />;
                        })}
                    </div>
                </div>
            </div>

            <div className="card show__mobile">
                <div className='card-body'>
                    <div className="list-group links-list__wrapper">
                        {linksList.map((link, index) => {
                            return <MobileListItem key={index} active={link.active} linkData={link}
                                             onCopyLink={copyLinkHandler} onDeleteLink={() => deleteLinkHandler(link)}
                            />;
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default LinksList;
