import React, {useState, useEffect} from 'react';
import LinksList from '../../components/LinksList/LinksList'
import SearchLinks from '../../components/SearchLinks/SearchLinks'
import NavHeader from '../../components/NavHeader/NavHeader'
import axiosConfig from '../../utils/api'
import DataPreloader from "../../components/DataPreloader/DataPreloader";
import ReactPaginate from 'react-paginate';

function Dashboard() {
    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [linksList, setLinksList] = useState([]);
    const [initLinksList, setInitLinksList] = useState([]);
    const [userId, setUserId] = useState(null);
    const [linksOffset, setLinksOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const getLinksList = (offset) => {
        setIsLoading(true);
        if (localStorage.getItem('access_token')) {
            axiosConfig.get(`get_user_links?offset=${offset}`).then(res => {
                setUserId(res.data.user_id)
                setLinksList(res.data.links)
                setInitLinksList(res.data.links)
                setPagesCount(res.data.pages_count)
                setIsLoading(false);
            }).catch(err => {
                console.log(err)
                setIsLoading(false);
            })
        }
    };
    useEffect(() => {
        getLinksList(linksOffset)
    }, []);

    const handleFilterData = props => {
        setLinksList(props)
        setIsSearch(true)
    };
    const handleClearFilter = () => {
        setLinksList([]);
        getLinksList(linksOffset)
        setIsSearch(false)
    };

    const handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 30);
        console.log(offset)
        setLinksOffset(offset)
        setCurrentPage(data.selected)
        getLinksList(offset)
    };

    return (
        <>
            <NavHeader/>
            <div className='container' style={{marginTop: '30px'}}>
                <div className='row'>
                    {isLoading ? <DataPreloader/>
                        :
                        <div className='col-md-12'>

                            {!isSearch ? <h3>Список ссылок</h3> : <h3>Результаты поиска</h3>}
                            <SearchLinks userId={userId} filterData={handleFilterData}
                                         clearFilter={handleClearFilter}/>

                            <LinksList linksList={linksList} getLinksList={() => getLinksList(linksOffset)}/>

                            <div className="card mt-5 mb-4">
                                <div className='card-body'>
                                    <ReactPaginate
                                        previousLabel={'назад'}
                                        nextLabel={'вперед'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pagesCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'page-item'}
                                        previousClassName={'page-item previous_wrapper'}
                                        nextClassName={'page-item previous_wrapper'}
                                        pageLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        forcePage={currentPage}
                                    />
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default Dashboard;
