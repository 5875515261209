import React from 'react';
import UsageItem from '../UsageItem/UsageItem';
import moment from 'moment';
import 'moment/locale/ru';

function UserSettings({userData}) {
    moment.locale('ru');
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className="row vertical-gap sm-gap justify-content-end">
                        <div className="col-12">
                            <label htmlFor="name">Имя</label>
                            <input className="form-control" id="name" value={userData.name} placeholder="Ваше имя"/>
                        </div>
                        <div className="col-12">
                            <label htmlFor="email">Email</label>
                            <input className="form-control" id="email" disabled value={userData.email}
                                   placeholder="Email"/>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-success" type="button">Сохранить</button>
                        </div>
                    </div>

                    <div className='card mt-20'>
                        <div className='card-body'>
                            <div className='row usage-data'>
                                <div className='col-12'>
                                    <h4>Статистика использования – <strong>{moment().format('MMMM')}</strong> </h4>

                                    <UsageItem
                                        title={'Количество ссылок'}
                                        param={'Количество ссылок'}
                                        count={userData.links_count}
                                        limit={userData.links_count_limit}
                                    />

                                    <UsageItem className={'mt-50'}
                                        title={'Количество кликов'}
                                        param={'Кликов по ссылкам'}
                                        count={userData.api_calls}
                                        limit={userData.api_calls_limit}
                                    />

                                    <UsageItem className={'mt-50'}
                                        title={'Количество доменов'}
                                        param={'Доменов'}
                                        count={userData.domains_count}
                                        limit={userData.domains_count_limit}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default UserSettings;
