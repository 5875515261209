import React, {useState, useEffect} from 'react';
import './LinkAnalytics.scss';
import LinkAnalyticsTable from './LinkAnalyticsTable'
import LinkAnalyticsChart from '../../components/LinkAnalyticsChart/LinkAnalyticsChart'
import DataPreloader from '../../components/DataPreloader/DataPreloader'
import moment from 'moment'
import * as R from 'ramda'


function LinkAnalytics({stats, dateRange, onDateChanged, handleDatesUpdate}) {
    const [tableData, setTableData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [chartAxisType, setChartAxisType] = useState('category');

    const handleFlags = (click) => {
        let country = 'us';
        if (click.country !== null) {
            country = click.country
        }
        click.headers['flag'] = `../../assets/images/flags/${country.toLowerCase()}.svg`;
    };

    const handleDates = (click) => {
        click['formatted_date'] = moment(click.date).utcOffset(3).format('DD-MM-YYYY / H:mm');


    };

    const handleChartData = (click, localData, formatter) => {
        if (formatter === 'x') {
            let date = moment(click.date).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0})
            date = new Date(date).getTime();
            localData.push({x: date, y: 1});
            setChartAxisType('datetime')
        } else {
            let date = moment(click.date).utcOffset(3).format(formatter).toString()
            localData.push({x: date, y: 1});
            setChartAxisType('category')
        }
    };

    const chartDataSorting = (series, localData) => {
        let unsortedData = [];
        let groupedData = R.reduceBy((acc, next) => acc + next.y, 0, (x) => x.x, localData);
        Object.keys(groupedData).forEach(key => {
            unsortedData.push({x: parseInt(key) || key, y: groupedData[key]})
        });
        series[0].data = R.sortBy(R.prop('x'), unsortedData);
        setChartData(series);
    };

    useEffect(() => {
        let series = [{data: [], name: 'клики', color: '#2fc787'}];
        let localData = [];
        stats.forEach(click => {
            console.log(click)
            handleDates(click);
            handleFlags(click);
            handleChartData(click, localData, 'x');
        });
        chartDataSorting(series, localData);
        setTableData(stats)
    }, []);

    const handleChartTabs = (e) => {
        console.log(e.tabParams.param)

        let series = [{data: [], name: 'клики', color: '#2fc787'}];
        let localData = [];

        stats.forEach(click => {
            handleChartData(click, localData, e.tabParams.param);
        });

        chartDataSorting(series, localData);
        setTableData(stats)

    };

    return (
        <>
            <div className="card">
                <div className='card-body'>
                    {chartData !== null
                        ? <LinkAnalyticsChart chartSeries={chartData} dateRange={dateRange}
                                              axisType={chartAxisType} onDateChanged={onDateChanged}
                                              chartTitle={'Статистика кликов'} handleDatesUpdate={handleDatesUpdate} changeTab={handleChartTabs} />
                        : null}
                    {tableData !== null
                        ? <LinkAnalyticsTable tableData={tableData} tableTitle='Статистика кликов за выбранный период'/>
                        : <DataPreloader/>
                    }

                </div>
            </div>
        </>
    );
}

export default LinkAnalytics;
