import axios from "axios";

let url = "http://0.0.0.0:5006/api/v1/";
if (process.env.NODE_ENV !== "development") {
    url = 'https://liky.cc/api/v1/'
}


const axiosConfig = axios.create({
    baseURL: url,
    responseType: "json",
    headers: {
        Accepted: 'appication/json',
        'Content-Type': 'application/json',
    },
});

axiosConfig.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.log('axiosConfig.interceptors.request.use', error)
    },
);

axiosConfig.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (401 === error.response.status) {
        console.log("AUTH ERROR")
        window.location.replace('/signin')
    }
});

export default axiosConfig;
