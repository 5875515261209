import React, {useState} from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import {DateRangePicker} from "react-dates";

function LinkAnalyticsChart({chartSeries, axisType, chartTitle, changeTab, dateRange, onDateChanged, handleDatesUpdate}) {
    const options = {
        title: {
            text: chartTitle
        },
        chart: {
            type: 'column'
        },
        xAxis: {
            type: axisType
        },
        legend: {
          enabled: false
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Клики'
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 1000
            }
        },
        series: chartSeries
    };

    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        {
            id: 0,
            text: 'Нет',
            param: 'x'
        },
        {
            id: 1,
            text: 'День',
            param: 'DD'
        },
        {
            id: 2,
            text: 'Неделя',
            param: 'WW'
        },
        {
            id: 3,
            text: 'Месяц',
            param: 'MM'
        }
    ];

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        changeTab({tabParams: tabs[tabIndex]})
    };

    const activeClass = 'bg-success text-light';
    const regularClass = 'btn-light text-secondary';

    const buttonsBlock = <div className="btn-group">
        {tabs.map((tab, index) => {
            return <button key={index} type="button"
                           className={`btn ${activeTab === index
                               ? activeClass
                               : regularClass}`}
                           onClick={() => handleTabClick(index)}
            >
                {tab.text}
            </button>
        })}
    </div>;

    const [focusedInput, setFocusedInput] = useState(null)

    const handleFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
    };

    return (
        <>
            <div className='card mb-15'>
                <div className='card-body'>
                    <div className="row mb-30">
                        <div className="col-md-6 text-left d-flex">
                            <DateRangePicker
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onDatesChange={onDateChanged}
                                focusedInput={focusedInput}
                                onFocusChange={handleFocusChange}
                                isOutsideRange={() => false}
                            />
                            <button className="btn btn-success btn-long ml-10 d-block" onClick={handleDatesUpdate}>Обновить</button>

                        </div>
                        <div className="col-md-6 chart-tabs__wrapper">
                            <div className="row group-wrapper d-flex align-items-center justify-content-end">
                                <span className="mr-25">Группировка данных: </span>
                                <div className="text-left">
                                    {buttonsBlock}
                                </div>
                            </div>
                        </div>
                    </div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </>
    );
}

export default LinkAnalyticsChart;
