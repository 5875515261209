import React from 'react';
import DataTable from 'react-data-table-component';

function LinkAnalyticsTable({tableData, tableTitle}) {
    const columns = [
        {
            name: 'Дата',
            selector: 'formatted_date',
            sortable: true
        },
        {
            name: 'Источник',
            selector: 'headers.host',
            sortable: true,
            cell: row => <div className='link-referrer'>{row.headers.host !== null ? row.headers.host : '–'}</div>
        },
        {
            name: 'Операционная система',
            selector: 'headers.user-agent',
            sortable: true
        },
        {
            name: 'Страна',
            selector: 'headers.flag',
            sortable: true,
            right: true,
            cell: row => <div>
                <img src={`/${row.headers.flag}`} alt={row.headers.flag} className='country-flag'/>

            </div>,
        }

    ];


    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <DataTable
                        title={tableTitle}
                        columns={columns}
                        data={tableData}
                        pagination
                    />
                </div>
            </div>
        </>
    );
}

export default LinkAnalyticsTable;
