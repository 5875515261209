import React from 'react';
import AuthForm from '../../components/AuthForm/AuthForm'
import NavHeader from '../../components/NavHeader/NavHeader'
import LinkAnalytics from "../../components/LinkAnalytics/LinkAnalytics";
import UserSettings from "../../components/UserSettings/UserSettings";

function Settings() {
    return (
        <>
            <div className='container auth-form__wrapper'>
                <div className='row'>
                    <div className='col-md-12'>
                        <AuthForm authType={'signin'} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;
