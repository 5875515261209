import React from 'react';

function UsageItem ({count, limit, title, param, className}) {

    const calcUsage = (count, limit) => {
        return count / limit * 100
    };

    return (
        <div className={`usage-item ${className}`}>
            <h5>{title}</h5>
            <div className='progress-meta'>
                <span className='text-muted'>{param}</span>
                <span className='text-muted'><strong>{count}</strong> из <strong>{limit}</strong> доступных </span>
            </div>
            <div className="progress">
                <div className='progress-bar bg-brand'
                     style={{width: `${calcUsage(count, limit)}%`}}>
                    {calcUsage(count, limit)}%
                </div>
            </div>
        </div>
    );
}

export default UsageItem;
