import React, {useState, useEffect} from 'react';
import './ListItem.scss';
import {BarChart2, Trash2, Edit2, Copy} from 'react-feather';
import moment from 'moment'
import 'moment/locale/ru';

function ListItem({linkData, active, onCopyLink, onDeleteLink}) {
    const [parsedParams, setParsedParams] = useState('')
    moment.locale('ru')
    let selectedDomain = 'liky.cc';
    if (linkData.selected_domain) {
        selectedDomain = linkData.selected_domain
    }
    const shortedLink = `https://${selectedDomain}/${linkData.url_param}`;
    const [hovered, setHovered] = useState(false);

    const handleUtms = () => {
      if(linkData.utm_params && Object.keys(linkData.utm_params).length) {
          let paramsLink = '/'
          Object.keys(linkData.utm_params).forEach(param => {
              paramsLink = paramsLink + `&${param}=${linkData.utm_params[param]}`
          });
          paramsLink = paramsLink.replace('&', '?');
          setParsedParams(paramsLink)
      }

    };

    const copyLink = () => {
        onCopyLink(shortedLink);
    };

    const editLink = () => {
        console.log('open edit modal for link', shortedLink)
    };

    const analyticsLink = () => {
        console.log('open analytics modal for link', shortedLink)
    };

    const deleteLink = () => {
        onDeleteLink(shortedLink)
    };

    useEffect(() => {
        handleUtms()
    }, [])

    return (
        <>
            <div className={`list-group-item list-group-item-action link-wrapper
           ${active ? 'list-group-item-success' : 'list-group-item-danger'}`}>
                <div className='creation-date'>
                    <span>
                        {moment(linkData.creation_date).format('DD-MM-YYYY')}
                        <br/>
                        <span style={{fontSize: 10, color: '#b8bcbf'}}>
                            ({moment(linkData.creation_date).fromNow()})
                        </span>
                    </span>


                </div>
                <div className='initial-url'>
                    <i style={{fontStyle: 'normal'}}>{linkData.initial_url}<i style={{color: '#a8aeb2'}}>{parsedParams}</i></i>
                </div>
                <div className='url-description'>
                    <div className='url-description__wrapper'>
                    {linkData.description ? <span>{linkData.description}</span> : <span>–</span>}
                    </div>
                </div>
                <div className='shorted-url'>
                    <a href={shortedLink} target="_blank" rel="noopener noreferrer">
                        {shortedLink}
                    </a>
                </div>
                <div className='link-clicks'>
                    {linkData.clicks}
                </div>
                <div className='link-tools'>
                    <div className='tool-item'>
                        <span onClick={copyLink}> <Copy size={18} color="#735ec3"/></span>
                    </div>
                    <div className='tool-item'>
                        <a href={`/create?param=${linkData.custom_param}`}> <Edit2 size={18}/></a>
                    </div>
                    <div className='tool-item'>
                         <a href={`/stats?param=${linkData.custom_param}`}><BarChart2 size={18}/></a>
                    </div>
                    <div className='tool-item'>
                        <span onClick={deleteLink}> <Trash2 size={18} color="#735ec3"/></span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListItem;
