import React from 'react';
import CreateLinkForm from '../../components/CreateLinkForm/CreateLinkForm'
import NavHeader from '../../components/NavHeader/NavHeader'

function CreateLink() {
    return (
        <>
            <NavHeader/>
            <div className='container' style={{marginTop: '30px'}}>
                <div className='row'>
                    <div className='col-md-12'>
                        <h3>Сократить ссылку</h3>
                        <CreateLinkForm/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateLink;
