import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard'
import CreateLink from './pages/Dashboard/CreateLink'
import Stats from './pages/Dashboard/Stats'
import Settings from './pages/Dashboard/Settings'
import SignUp from './pages/Dashboard/SignUp'
import SignIn from './pages/Dashboard/SignIn'
import PrivateRoute from "./utils/PrivateRoute";
import {AuthProvider} from "./utils/Auth";
import './assets/css/bootstrap-custom.css';
import './assets/css/rootui.css';
import './assets/css/custom.css';


function App() {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <PrivateRoute exact path="/" component={Dashboard}/>
                    <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                    <PrivateRoute exact path="/create" component={CreateLink}/>
                    <PrivateRoute exact path="/stats" component={Stats}/>
                    <PrivateRoute exact path="/settings" component={Settings}/>
                    <Route exact path="/signup" component={SignUp}/>
                    <Route exact path="/signin" component={SignIn}/>
                    <Redirect to="/signin"/>
                </Switch>
            </Router>
        </AuthProvider>
    );
}

export default App;
