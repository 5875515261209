import React from 'react';

function DataPreloader() {


    return (
        <div className='preloader'>
            <div className="spinner-border text-brand" role="status"><span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

export default DataPreloader;
