import React, {useState} from 'react';
import './NavHeader.scss';
import {PlusCircle, Menu, X} from 'react-feather';
import {NavLink} from "react-router-dom";


function NavHeader() {
    const [menu, setMenu] = useState([
        {url: '/dashboard', title: 'Список ссылок'},
        {url: '/stats', title: 'Статистика'},
        {url: '/settings', title: 'Настройки'}
    ]);
    const handleExit = () => {
      localStorage.removeItem('access_token');
      window.location.replace('/signin')
    };
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    return (
        <nav className='rui-navbar rui-navbar-top rui-navbar-sticky main-navbar py-10'>
            <div className='container dashboard-nav__wrapper'>
                <ul className="nav dashboard-nav__left">
                    <li className="nav-item">
                        <a href="#" className="rui-navbar-logo dashboard-main-logo">
                            <img src="../../assets/images/logo.svg" alt="shortLinks" width="145"/>
                        </a>
                    </li>
                    {menu.map((link, index) => {
                        return (
                            <li className="nav-item" key={index}>
                                <NavLink className="nav-link" activeClassName="active text-success disabled"
                                         to={link.url}>{link.title}</NavLink>
                            </li>
                        )
                    })}
                </ul>

                <ul className='nav dashboard-nav__right'>
                    <li className='dropdown dropdown-hover dropdown-keep-open dropdown-triangle rui-dropdown-triangle-ready'>
                        <NavLink className="nav-link" activeClassName="active text-success disabled"
                                 to='/create'>
                            <PlusCircle size={13} className='mr-5'/> Сократить ссылку
                        </NavLink>
                    </li>
                    <li className='dropdown dropdown-hover dropdown-keep-open dropdown-triangle rui-dropdown-triangle-ready'>
                        <span className="nav-link exit__link" onClick={() => handleExit()} >Выйти </span>
                    </li>
                    <li>
                        <span className="burger-menu__wrapper" onClick={() => setShowMobileMenu(!showMobileMenu)}>
                            <Menu size={18} className=''/>
                        </span>
                    </li>
                </ul>

            </div>
            {showMobileMenu &&
            <div className="mobile-dropdown__menu">
                <div className="list-group">
                    {menu.map((link, index) => {
                        return (
                            <a href={link.url} key={index}
                               className="list-group-item list-group-item-action">
                                {link.title}
                            </a>
                        )
                    })}
                    <a href="/create"
                       className="list-group-item list-group-item-action">
                        Сократить ссылку
                    </a>
                </div>
            </div>
            }
        </nav>
    );
}

export default NavHeader;
