import React, {useState} from 'react';
import './ListItem.scss';
import {BarChart2, Trash2, Edit2, Copy, ToggleLeft, ToggleRight} from 'react-feather';
import moment from 'moment'
import 'moment/locale/ru';

function MobileListItem({linkData, active, onCopyLink, onDeleteLink}) {
    moment.locale('ru')
    const shortedLink = `https://liky.cc/${linkData.url_param}`;
    const [hovered, setHovered] = useState(false);

    const copyLink = () => {
        onCopyLink(shortedLink);
    };

    const editLink = () => {
        console.log('open edit modal for link', shortedLink)
    };

    const analyticsLink = () => {
        console.log('open analytics modal for link', shortedLink)
    };

    const deleteLink = () => {
        onDeleteLink(shortedLink)
    };

    return (
        <>
            <div className='card mb-20  active-link__mobile-card'>
                <div className='card-body'>
                    <div className='creation-date mb-20'>
                        <span className="badge badge-light px-7 py-9">
                            {moment(linkData.creation_date).fromNow()}
                        </span>
                        {active ?
                            <span className="badge badge-success px-7">
                            <ToggleRight size={18}/>
                            </span>
                            :
                            <span className="badge badge-danger px-7">
                            <ToggleLeft size={18}/>
                            </span>
                        }
                    </div>

                    <div className='initial-url mb-20' onClick={() => setHovered(!hovered)}>
                        <p className="mb-0 text-muted">Исходная ссылка</p>
                        <span className={`${hovered ? '' : 'initial_url--short'}`}>
                            {linkData.initial_url}
                        </span>
                    </div>

                    <div className='shorted-url mb-20'>
                        <p className="mb-0 text-muted">Сокращенная ссылка</p>
                        <a href={shortedLink} target="_blank" rel="noopener noreferrer">
                            {shortedLink}
                        </a>
                    </div>

                    <div className='link-clicks'>
                        <p className="mb-0 text-muted">Кликов по ссылке</p>
                        <span className="badge badge-dark px-15 py-9 mt-3">
                            {linkData.clicks}
                        </span>
                    </div>

                    <div className='link-tools__wrapper'>
                        <div className='link-tools'>
                            <div className='tool-item'>
                                <span onClick={copyLink}> <Copy size={18}/></span>
                            </div>
                            <div className='tool-item'>
                                <span onClick={editLink}> <Edit2 size={18}/></span>
                            </div>
                            <div className='tool-item'>
                                <span onClick={analyticsLink}> <BarChart2 size={18}/></span>
                            </div>
                            <div className='tool-item'>
                                <span onClick={deleteLink}> <Trash2 size={18}/></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default MobileListItem;
