import React, {useEffect, useState} from 'react';
import CreateLinkForm from '../../components/CreateLinkForm/CreateLinkForm'
import NavHeader from '../../components/NavHeader/NavHeader'
import LinkAnalytics from "../../components/LinkAnalytics/LinkAnalytics";
import axiosConfig from "../../utils/api";
import moment from 'moment'

function Stats() {
    const [linkStats, setLinkStats] = useState(null);
    const [dateRange, setDateRange] = useState({startDate: moment().subtract(30, 'days'), endDate: moment()});

    const handleDateChange = ({ startDate, endDate }) => {
        console.log({ startDate, endDate })
        setDateRange({ startDate, endDate });
      };

    const getUserStats = (linkParam = '') => {
        setLinkStats(null)
        let start = dateRange.startDate.format('DD-MM-YYYY');
        let end = dateRange.endDate.format('DD-MM-YYYY');
        axiosConfig.get(`get_user_stats?start_date=${start}&end_date=${end}${linkParam}`).then(res => {
            setLinkStats(res.data.stats)
        }).catch(err => {
            console.log(err)
        })
    };
    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let linkParam = params.get('param');
        if (linkParam) {
            getUserStats(`&link_param=${linkParam}`)
        } else {
            getUserStats()
        }
    }, []);

    return (
        <>
            <NavHeader/>
            <div className='container' style={{marginTop: '30px'}}>
                <div className='row'>
                    <div className='col-md-12'>
                        <h3>Общая статистика</h3>
                        {linkStats && <LinkAnalytics stats={linkStats} dateRange={dateRange} handleDatesUpdate={() => getUserStats()}
                                                     onDateChanged={handleDateChange} /> }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Stats;
