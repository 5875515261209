import React, {useState, useEffect} from 'react';
import './CreateLinkForm.scss';
import {PlusCircle, Settings} from 'react-feather';
import DataPreloader from '../../components/DataPreloader/DataPreloader'
import axiosConfig from "../../utils/api";
import {useHistory} from "react-router-dom";

function makeParam(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function CreateLinkForm() {
    const history = useHistory();
    const [userDomains, setUserDomains] = useState(['liky.cc']);
    const [initLink, setInitLink] = useState(undefined);
    const [linkDescription, setLinkDescription] = useState(undefined);
    const [linkSegment, setLinkSegment] = useState(undefined);
    const [linkAge, setLinkAge] = useState(0);
    const [linkAgeRedirect, setLinkAgeRedirect] = useState(undefined);
    const [linkParam, setLinkParam] = useState(undefined);
    const [linkDomain, setLinkDomain] = useState('liky.cc');
    const [disableLinkParam, setDisableLinkParam] = useState(false);
    const [generateLinkParam, setGenerateLinkParam] = useState(false);

    const [invalidInitLink, setInvalidInitLink] = useState(false);
    const [invalidLinkParam, setInvalidLinkParam] = useState(false);
    const [doubledLinkParam, setDoubledLinkParam] = useState(false);

    useEffect(() => {
        axiosConfig.get('get_user_data').then(res => {
            console.log(res.data.user.domains_list)
            let localDomains = [...userDomains]
            res.data.user.domains_list.forEach(domain => {
                localDomains.push(domain.domain_name)
            })
            setUserDomains(localDomains)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const initUTMS = {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_term: null,
    };
    const [utmParams, setUtmParams] = useState(initUTMS);

    const [showExtraSettings, setShowExtraSettings] = useState(false);

    const checkLinkParam = async (param) => {
        return await axiosConfig.get(`check_link_param?param=${param}`)
    };

    const createShortLink = async (payload) => {
        return await axiosConfig.post(`create_link`, payload)
    };

    const updateShortLink = async (payload) => {
        return await axiosConfig.put(`update_link`, payload)
    };

    const handleLinkParam = () => {
        if (!linkParam) {
            setGenerateLinkParam(true)
            let newParam = makeParam(4);

            checkLinkParam(newParam).then(res => {
                setGenerateLinkParam(false);
                if (!res.data.link) {
                    setLinkParam(newParam);
                } else {
                    setLinkParam(`${newParam}N`);
                }
            });
        }
    };

    const sendForm = () => {
        setInvalidInitLink(!initLink);
        setInvalidLinkParam(!linkParam);
        if (initLink && linkParam) {
            const linkPayload = {
                url: initLink,
                description: linkDescription,
                segment: linkSegment,
                custom_param: linkParam,
                utm_params: handleParams(),
                selected_domain: linkDomain,
                link_age: linkAge,
                link_age_redirect: linkAgeRedirect
            };
            console.log(linkPayload)
            if (!disableLinkParam) {
                createShortLink(linkPayload)
                    .then(() => {
                        setDoubledLinkParam(false);
                        history.push("/dashboard");
                    })
                    .catch(err => {
                        if (err.response.status === 409) {
                            setDoubledLinkParam(true)
                        }
                    })
            } else {
                const linkPayload = {
                    initial_url: initLink,
                    description: linkDescription,
                    segment: linkSegment,
                    custom_param: linkParam,
                    utm_params: handleParams(),
                    link_age: linkAge,
                    link_age_redirect: linkAgeRedirect
                };
                console.log(linkPayload)
                updateShortLink(linkPayload)
                    .then(res => {
                        console.log(res)
                        history.push("/dashboard");
                    })
                    .catch(err => {
                        console.log(err)
                    });
                console.log('UPDATE LINK')
            }
        } else {
            console.log('ERROR')
        }
    };

    const handleDomainChange = e => {
        setLinkDomain(e.target.value)
    };
    const handleUTM = (e) => {
        let param = e.target.id;
        let value = e.target.value;
        let obj = {};
        obj[param] = value;
        setUtmParams(prev => ({
            ...prev,
            [param]: value
        }));
    };

    const handleParams = () => {
        let nonEmptyParams = {};
        Object.keys(utmParams).forEach(e => {
            if (utmParams[e] !== null) {
                nonEmptyParams[e] = utmParams[e];
            }
        });
        return nonEmptyParams
    };

    const onBlurCheckLinkParam = (param) => {
         checkLinkParam(param).then(res => {
             if(res.data.link) {
                 setDoubledLinkParam(true)
             } else {
                 setDoubledLinkParam(false)
             }
         })
    };

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let linkParam = params.get('param');
        if (linkParam) {
            checkLinkParam(linkParam).then(res => {
                setDisableLinkParam(true);
                let link = res.data.link;
                let UTMS = {
                    utm_source: null,
                    utm_medium: null,
                    utm_campaign: null,
                    utm_term: null,
                };
                if (link.utm_params) {
                    UTMS = {
                        utm_source: link.utm_params.utm_source,
                        utm_medium: link.utm_params.utm_medium,
                        utm_campaign: link.utm_params.utm_campaign,
                        utm_term: link.utm_params.utm_term,
                    };
                }
                setInitLink(link.initial_url);
                setLinkDescription(link.description);
                setLinkSegment(link.segment);
                setLinkParam(link.custom_param);
                setUtmParams(UTMS);
            })
        }
    }, []);

    const extraSettings = <div className='card-body'>
        <h4>
            Дополнительные настройки
        </h4>
        <div className='row'>
            <div className='col-12'>
                <div className='form-group-wrapper'>
                    <h5 className='text-secondary'>Добавить UTM-метки</h5>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor='utm_source'>UTM Source</label>
                            <input type='text' className='form-control' value={utmParams.utm_source}
                                   onChange={e => handleUTM(e)}
                                   id='utm_source' placeholder='UTM Source'/>
                        </div>
                        <div className='col-md-6 sm-mt__20'>
                            <label htmlFor='utm_medium'>UTM Medium</label>
                            <input type='text' className='form-control' value={utmParams.utm_medium}
                                   onChange={e => handleUTM(e)}
                                   id='utm_medium' placeholder='UTM Medium'/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor='utm_campaign'>UTM Campaign</label>
                            <input type='text' className='form-control' value={utmParams.utm_campaign}
                                   onChange={e => handleUTM(e)}
                                   id='utm_campaign' placeholder='UTM Campaign'/>
                        </div>
                        <div className='col-md-6 sm-mt__20'>
                            <label htmlFor='utm_term'>UTM Term</label>
                            <input type='text' className='form-control' value={utmParams.utm_term}
                                   onChange={e => handleUTM(e)}
                                   id='utm_term' placeholder='UTM Term'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <div className='form-group-wrapper'>
                    <h5 className='text-secondary'>Срок жизни</h5>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor='utm_source'>Срок жизни в днях</label>
                            <input type='number' className='form-control' value={linkAge}
                                   onChange={e => setLinkAge(parseInt(e.target.value))}
                                   id='utm_source' placeholder='Срок жизни в днях'/>
                        </div>
                        <div className='col-md-6 sm-mt__20'>
                            <label htmlFor='utm_medium'>Куда отправлять трафик</label>
                            <input type='text' className='form-control' value={linkAgeRedirect}
                                   onChange={e => setLinkAgeRedirect(e.target.value)}
                                   id='utm_medium' placeholder='Ссылка для редиректа'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;

    return (
        <div className='create-link-form'>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12'>
                            <label htmlFor='initLink'>Ссылка</label>

                            <input type='text'
                                   className={`form-control ${invalidInitLink && !initLink ? 'invalid_input' : ''}`}
                                   id='initLink' placeholder='Введите ссылку' onBlur={handleLinkParam}
                                   required onChange={e => setInitLink(e.target.value)} value={initLink}/>
                            {invalidInitLink && !initLink && <div className="invalid-feedback">Обязательное поле</div>}
                        </div>
                    </div>
                    <div className='row pb-25'>
                        <div className='col-md-6'>
                            <label htmlFor='linkDomain'>Домен</label>
                            <select value={linkDomain} className='custom-select'
                                    onChange={handleDomainChange}>
                                {userDomains.map(domain => {
                                    return <option key={domain} value={domain}>{domain}</option>
                                })}
                            </select>
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='initLink'>Параметр ссылки</label>
                            {generateLinkParam && <span className="input-preloader__wrapper"><DataPreloader/></span>}
                            {!disableLinkParam ?
                                <input type='text'
                                       className={`form-control ${(invalidLinkParam && !linkParam) || doubledLinkParam ? 'invalid_input' : ''}`}
                                       id='initLink' placeholder='окончание ссылки'
                                       value={linkParam}
                                       onBlur={() => onBlurCheckLinkParam(linkParam)}
                                       required onChange={e => setLinkParam(e.target.value)}/>
                                : <input type='text'
                                         className='form-control'
                                         id='initLink' placeholder='окончание ссылки'
                                         value={linkParam}
                                         onBlur={() => onBlurCheckLinkParam(linkParam)}
                                         required disabled/>
                            }
                            {invalidLinkParam && !linkParam &&
                            <div className="invalid-feedback">Обязательное поле</div>}
                            {doubledLinkParam &&
                            <div className="invalid-feedback">Ссылка с таким параметром уже существует</div>}
                        </div>
                    </div>
                    <div className='row mt-25'>
                        <div className='col-12'>

                            <h5 className='text-secondary'>Добавить сегмент и описание</h5>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor='linkDescription'>Описание ссылки</label>
                                    <input type='text' className='form-control' value={linkDescription}
                                           onChange={e => setLinkDescription(e.target.value)}
                                           id='linkDescription' placeholder='Описание'/>
                                </div>

                                <div className='col-md-6 sm-mt__20'>
                                    <label htmlFor='linkDescription'>Произвольный сегмент</label>
                                    <input type='text' className='form-control' value={linkSegment}
                                           onChange={e => setLinkSegment(e.target.value)}
                                           id='linkDescription' placeholder='Сегмент'/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {showExtraSettings ? extraSettings : null}
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12 text-right'>
                            <button type="button" className="btn btn-round btn-grey-2"
                                    onClick={() => setShowExtraSettings(!showExtraSettings)}>
                                <Settings size={12}/>
                            </button>
                            {!disableLinkParam
                                ? <button type="button" className="btn btn-success" onClick={sendForm}>Создать</button>
                                : <button type="button" className="btn btn-success" onClick={sendForm}>Обновить</button>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CreateLinkForm;
