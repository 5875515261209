import React, {useState, useEffect} from 'react';
import './SearchLinks.scss';
import moment from 'moment'
import 'moment/locale/ru';
import algoliasearch from 'algoliasearch/lite';
import {Search, X} from "react-feather";

const client = algoliasearch('7LKH5M0IBS', '4f82c80f8d6386ee057608cc7d019d8c');
const index = client.initIndex('links');


// function Hit(props) {
//     moment.locale('ru')
//     const shortedLink = `https://liky.cc/${props.hit.url_param}`;
//     const [hovered, setHovered] = useState(false);
//
//     const copyLink = () => {
//         // onCopyLink(shortedLink);
//     };
//
//     const editLink = () => {
//         console.log('open edit modal for link', shortedLink)
//     };
//
//     const analyticsLink = () => {
//         console.log('open analytics modal for link', shortedLink)
//     };
//
//     const deleteLink = () => {
//         // onDeleteLink(shortedLink)
//     };
//
//     return (
//         <>
//             <div className={`list-group-item list-group-item-action search-results__wrapper`}>
//                 <div className='initial-url'
//                      onMouseEnter={() => setHovered(true)}
//                      onMouseLeave={() => setHovered(false)}>
//                     {props.hit.initial_url}
//                     {hovered
//                         ? <span className='badge badge-secondary'>{props.hit.initial_url}</span>
//                         : null
//                     }
//                 </div>
//                 <div className='shorted-url'>
//                     <a href={shortedLink} target="_blank" rel="noopener noreferrer">
//                         {shortedLink}
//                     </a>
//                 </div>
//                 <div className='link-clicks'>
//                     <span><strong>Кликов: </strong>{props.hit.clicks}</span>
//                 </div>
//                 <div className='link-segment'>
//                     <span><strong>Сегмент: </strong>{props.hit.segment}</span>
//                 </div>
//                 <div className='link-tools'>
//                     <div className='tool-item'>
//                         <span onClick={copyLink}> </span>
//                     </div>
//                     <div className='tool-item'>
//                         <span onClick={editLink}> </span>
//                     </div>
//                     <div className='tool-item'>
//                         <span onClick={analyticsLink}> </span>
//                     </div>
//                     <div className='tool-item'>
//                         <span onClick={deleteLink}> </span>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

function SearchLinks({userId, filterData, clearFilter}) {
    const [searchQuery, setSearchQuery] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const handleSearch = e => {
        e.preventDefault();
        setShowResults(true);
        index.search(searchQuery).then(({hits}) => {
            filterData(hits)
        });
    };

    const handleSearchClose = e => {
        e.preventDefault();
        setShowResults(false);
        setSearchQuery(null);
        clearFilter()
    };

    return (
        <>
            {userId &&
            <div className="card mb-20">
                <div className='card-body'>

                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={handleSearch}>
                                <div className="search-form__wrapper">
                                    <input type="text" className="form-control search-form__input"
                                           onChange={e => setSearchQuery(e.target.value)}
                                           value={searchQuery}
                                           placeholder="Найти ссылку"/>
                                    {!showResults
                                    && <button className="btn btn-success search-form__btn" type="submit">
                                        <Search size={16}/>
                                    </button>

                                    }
                                    {showResults && <button className="btn btn-dark search-form__btn"
                                                            onClick={handleSearchClose}>
                                        <X size={16}/>
                                    </button>}
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
            }
        </>
    );
}

export default SearchLinks;
